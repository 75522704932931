<template>
	<div class="content flex-space-between">
		<div class="left">
			<AsideNavMenu
				:index="navIndex"
				:list="navMenuList"
				:title="navTitle"
				@navMenuClick="navMenuClick"
				@navMenuLink="navMenuLink"
			></AsideNavMenu>
		</div>
		<div class="right">
			<!-- :id="listId" -->
			<router-view :title="listTitle"></router-view>
		</div>
	</div>
</template>

<script>
import {EventBus} from '@/utils/event-bus'
import {postTitleList, postTitle} from '@/service/api/index'
import AsideNavMenu from '@/components/aside/navmenu.vue'

export default {
	components: {AsideNavMenu},
	data() {
		return {
			navMenuList: [],
			navIndex: null,
			navTitle: '',
			listTitle: '',
			navbarId: null,
			listId: null,
			isNavBar: false
		}
	},
	mounted() {
		window.addEventListener('beforeunload', this.beforeunloadFn())
		EventBus.$on('selectClick', (res) => {
			this.navIndex = res.index
			EventBus.$emit('aside', this.navMenuList[res.index])
		})
		EventBus.$on('navbar', (res) => {
			this.isNavBar = true
			this.navTitle = res.name
			if (res.id != this.navbarId) {
				this.navbarId = res.id
				sessionStorage.setItem('navbarId', res.id)
				sessionStorage.setItem('navTitle', res.name)
				// 标题列表
				this.postTitleList(res.id)
			}
		})
	},
	destroyed() {
		EventBus.$off('selectClick')
		EventBus.$off('navbar')
		window.removeEventListener('beforeunload', this.beforeunloadFn())
	},

	methods: {
		postTitle(id, type) {
			postTitle({
				id,
				type
			}).then((res) => {
				// console.log(res)
			})
		},
		// 标题列表
		postTitleList(id) {
			postTitleList({id}).then((res) => {
				// this.navMenuList = Object.freeze(res.msg)
				this.navMenuList = Object.freeze(res.msg.data)
				this.navTitle = res.msg.name
				sessionStorage.setItem('navTitle', res.msg.name)
				this.navIndex = this.navMenuList.findIndex((item) => item.is_show == 1)
				if (!this.$route.params.home) {
					this.listTitle = this.navMenuList[this.navIndex].name
					this.listId = this.navMenuList[this.navIndex].id
				}
			})
		},
		// 内容列表
		navMenuClick(index) {
			this.navIndex = index
			this.listTitle = this.navMenuList[index].name
			this.$store.state.listId = this.navMenuList[index].id
			this.$store.state.status = this.navMenuList[index].status
			sessionStorage.setItem('navbarId', this.navMenuList[index].id)
			sessionStorage.setItem('currentIDChoose', this.navMenuList[index].id)
			sessionStorage.setItem('params', JSON.stringify(this.navMenuList[index]))
			this.listId = this.navMenuList[index].id
		},
		navMenuLink(item) {
			this.listId = item.id
			// EventBus.$emit('listId', item.id)
		},
		beforeunloadFn() {
			this.navbarId = sessionStorage.getItem('navbarId')
			// this.navbarId = this.$store.state.listId
			this.navTitle = sessionStorage.getItem('navTitle')
			const navType = sessionStorage.getItem('navType')
			if (this.navbarId) {
				// 标题列表
				this.postTitleList(this.navbarId)
				if (this.isNavBar) {
					this.postTitle(this.navbarId, navType)
				}
			}
		}
	}
}
</script>

<style lang="less" scoped>
.content {
	align-items: flex-start;
}

.right {
	width: 1160px;
	margin-left: 4px;
	background: #fff;
	border-radius: 3px;
}
</style>
